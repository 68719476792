import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedProject: {
    ID: null,
    title: '',
    description: '',
    files: [],
    keywordsStatistic: {},
    tags: {},
    blinding: false,
    conflicts: 0,
    rejectionReasons: {},
    excludeKeywords: [],
    includeKeywords: [],
    keywordGroups: [],
    decisionsFilterMatch: {},
    tagsFilterMatch: {},
    rejectionReasonsFilterMatch: {},
    activeKeywordGroups: [],
    includeColorGroups: [],
    excludeColorGroups: [],
    activeTags: {},
    activeIncludeKeywords: {},
    activeExcludeKeywords: {},
    activeDecisions: {},
    activeRejectionReasons: {},
    conflictFilter: false,
    users: [],
    searchText: '',
    searchHistory: [],
    sortBy: 'id',
    sortOrder: 'asc'
  },
  projects: [],
  currentUser: {},
  hotkeysConfig: {
    exclude: 'e',
    include: 'i',
    maybe: 'm'
  }
};

const projectSlice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setHotkeysConfig(state, action) {
      state.hotkeysConfig = action.payload;
    },
    setSelectedProject(state, action) {
      if (action.payload !== null) {
        state.selectedProject = {
          ...state.selectedProject,
          ...action.payload
        };
      } else {
        state.selectedProject = initialState.selectedProject;
      }
      state.conflictsPerBatch = action.payload.conflicts;
      state.decisionsFilterMatch = action.payload.keywordsStatistic;
      state.tagsFilterMatch = action.payload.tags;
      state.rejectionReasonsFilterMatch = action.payload.rejectionReasons;
    },
    addIncludeKeyword(state, action) {
      state.selectedProject.includeKeywords = [
        ...state.selectedProject.includeKeywords,
        action.payload
      ];
    },
    addManyIncludeKeywords(state, action) {
      state.selectedProject.includeKeywords = [
        ...state.selectedProject.includeKeywords,
        ...action.payload
      ];
    },
    addManyExcludeKeywords(state, action) {
      state.selectedProject.excludeKeywords = [
        ...state.selectedProject.excludeKeywords,
        ...action.payload
      ];
    },
    updateIncludeKeyword(state, action) {
      state.selectedProject.includeKeywords = state.selectedProject.includeKeywords.map(
        (keyword) => {
          if (keyword.ID === action.payload.ID) {
            return action.payload;
          } else {
            return keyword;
          }
        }
      );
    },
    removeIncludeKeyword(state, action) {
      state.selectedProject.includeKeywords = state.selectedProject.includeKeywords.filter(
        (includeKeyword) => includeKeyword.ID !== action.payload
      );
    },
    removeManyIncludeKeywords(state, action) {
      state.selectedProject.includeKeywords = state.selectedProject.includeKeywords.filter(
        (includeKeyword) => !action.payload.includes(includeKeyword.ID)
      );
    },
    removeManyExcludeKeywords(state, action) {
      state.selectedProject.excludeKeywords = state.selectedProject.excludeKeywords.filter(
        (excludeKeyword) => !action.payload.includes(excludeKeyword.ID)
      );
    },
    addExcludeKeyword(state, action) {
      state.selectedProject.excludeKeywords = [
        ...state.selectedProject.excludeKeywords,
        action.payload
      ];
    },
    updateExcludeKeyword(state, action) {
      state.selectedProject.excludeKeywords = state.selectedProject.excludeKeywords.map(
        (keyword) => {
          if (keyword.ID === action.payload.ID) {
            return action.payload;
          } else {
            return keyword;
          }
        }
      );
    },
    removeExcludeKeyword(state, action) {
      state.selectedProject.excludeKeywords = state.selectedProject.excludeKeywords.filter(
        (excludeKeyword) => excludeKeyword.ID !== action.payload
      );
    },
    updateActiveTags(state, action) {
      state.selectedProject.activeTags = {
        ...state.selectedProject.activeTags,
        [action.payload.id]: action.payload.status
      };
    },
    updateActiveDecisions(state, action) {
      state.selectedProject.activeDecisions = {
        ...state.selectedProject.activeDecisions,
        [action.payload.id]: action.payload.status
      };
    },
    updateActiveRejectionReason(state, action) {
      state.selectedProject.activeRejectionReasons = {
        ...state.selectedProject.activeRejectionReasons,
        [action.payload.id]: action.payload.status
      };
    },
    updateActiveIncludeKeywords(state, action) {
      state.selectedProject.activeIncludeKeywords = {
        ...state.selectedProject.activeIncludeKeywords,
        [action.payload.id]: action.payload.status
      };
    },
    removeActiveIncludeKeyword(state, action) {
      state.selectedProject.activeIncludeKeywords = {
        ...state.selectedProject.activeIncludeKeywords,
        [action.payload.keyword]: false
      };
    },
    updateActiveExcludeKeywords(state, action) {
      state.selectedProject.activeExcludeKeywords = {
        ...state.selectedProject.activeExcludeKeywords,
        [action.payload.id]: action.payload.status
      };
    },
    removeActiveExcludeKeyword(state, action) {
      state.selectedProject.activeExcludeKeywords = {
        ...state.selectedProject.activeExcludeKeywords,
        [action.payload.keyword]: false
      };
    },
    setUsers(state, action) {
      state.selectedProject.users = action.payload;
    },
    addUser(state, action) {
      state.selectedProject.users = [...state.selectedProject.users, action.payload];
    },
    removeUser(state, action) {
      state.selectedProject.users = state.selectedProject.users.filter(
        (user) => user.email !== action.payload
      );
    },
    setProjects(state, action) {
      state.projects = action.payload;
    },
    addProject(state, action) {
      state.projects = [...state.projects, action.payload];
    },
    addKeywordGroup(state, action) {
      state.selectedProject.keywordGroups = [
        ...state.selectedProject.keywordGroups,
        action.payload
      ];
    },
    removeKeywordGroup(state, action) {
      state.selectedProject.keywordGroups = state.selectedProject.keywordGroups.filter(
        (group) => group.ID !== action.payload
      );
    },
    updateKeywordGroup(state, action) {
      state.selectedProject.keywordGroups = state.selectedProject.keywordGroups.map((group) => {
        if (group.ID === action.payload.ID) {
          return action.payload;
        } else {
          return group;
        }
      });
    },
    addIncludeColorGroup(state, action) {
      state.selectedProject.includeColorGroups = [
        ...state.selectedProject.includeColorGroups,
        action.payload
      ];
    },
    removeIncludeColorGroup(state, action) {
      state.selectedProject.includeColorGroups = state.selectedProject.includeColorGroups.filter(
        (group) => group.ID !== action.payload
      );
    },
    updateIncludeColorGroup(state, action) {
      state.selectedProject.includeColorGroups = state.selectedProject.includeColorGroups.map(
        (group) => {
          if (group.ID === action.payload.ID) {
            return action.payload;
          } else {
            return group;
          }
        }
      );
    },
    addExcludeColorGroup(state, action) {
      state.selectedProject.excludeColorGroups = [
        ...state.selectedProject.excludeColorGroups,
        action.payload
      ];
    },
    removeExcludeColorGroup(state, action) {
      state.selectedProject.excludeColorGroups = state.selectedProject.excludeColorGroups.filter(
        (group) => group.ID !== action.payload
      );
    },
    updateExcludeColorGroup(state, action) {
      state.selectedProject.excludeColorGroups = state.selectedProject.excludeColorGroups.map(
        (group) => {
          if (group.ID === action.payload.ID) {
            return action.payload;
          } else {
            return group;
          }
        }
      );
    },
    addSearchHistoryItem(state, action) {
      if (
        state.selectedProject.searchHistory.every(
          (search) => search.searchQuery !== action.payload.searchQuery
        )
      ) {
        state.selectedProject.searchHistory = [
          ...state.selectedProject.searchHistory,
          action.payload
        ];
      }
    },
    setSortBy(state, action) {
      state.selectedProject.sortBy = action.payload;
    },
    setSortOrder(state, action) {
      state.selectedProject.sortOrder = action.payload;
    },
    setConflictFilter(state, action) {
      state.selectedProject.conflictFilter = action.payload;
    },
    setActiveKeywordGroup(state, action) {
      state.selectedProject.activeKeywordGroups = [
        ...state.selectedProject.activeKeywordGroups,
        action.payload
      ];
    },
    removeActiveKeywordGroup(state, action) {
      state.selectedProject.activeKeywordGroups = state.selectedProject.activeKeywordGroups.filter(
        (group) => group.ID !== action.payload
      );
    },
    setDecisionsFilterMatch(state, action) {
      state.selectedProject.decisionsFilterMatch = { ...action.payload };
    },
    setTagsFilterMatch(state, action) {
      state.selectedProject.tagsFilterMatch = { ...action.payload };
    },
    setRejectionReasonsFilterMatch(state, action) {
      state.selectedProject.rejectionReasonsFilterMatch = { ...action.payload };
    }
  }
});

export default projectSlice.reducer;

export const {
  setSelectedProject,
  addIncludeKeyword,
  removeIncludeKeyword,
  addExcludeKeyword,
  removeExcludeKeyword,
  updateActiveTags,
  updateActiveDecisions,
  updateActiveRejectionReason,
  updateActiveIncludeKeywords,
  updateActiveExcludeKeywords,
  setUsers,
  addUser,
  removeUser,
  setProjects,
  addProject,
  addKeywordGroup,
  removeKeywordGroup,
  updateKeywordGroup,
  updateExcludeKeyword,
  updateIncludeKeyword,
  addSearchHistoryItem,
  setCurrentUser,
  setSortBy,
  setSortOrder,
  setConflictFilter,
  addIncludeColorGroup,
  removeIncludeColorGroup,
  updateIncludeColorGroup,
  addExcludeColorGroup,
  removeExcludeColorGroup,
  updateExcludeColorGroup,
  removeManyIncludeKeywords,
  addManyIncludeKeywords,
  removeManyExcludeKeywords,
  addManyExcludeKeywords,
  setActiveKeywordGroup,
  removeActiveKeywordGroup,
  removeActiveExcludeKeyword,
  removeActiveIncludeKeyword,
  setRejectionReasonsFilterMatch,
  setTagsFilterMatch,
  setDecisionsFilterMatch,
  setHotkeysConfig
} = projectSlice.actions;
