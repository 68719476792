import React, { lazy } from 'react';

import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

const Projects = Loadable(lazy(() => import('../pages/Projects/Projects')));
const Screener = Loadable(lazy(() => import('../pages/Screener/Screener')));
const UserManagement = Loadable(lazy(() => import('../pages/UserManagement/UserManagement')));
const Export = Loadable(lazy(() => import('../pages/Export/Export')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Projects />,
      exact: true
    },
    {
      path: '/projects',
      element: <Projects />,
      exact: true
    },
    {
      path: '/projects/:id/users',
      element: <UserManagement />
    },
    {
      path: '/screener',
      element: <Screener />
    },
    {
      path: '/export',
      element: <Export />
    }
  ]
};

export default MainRoutes;
