import axios from 'axios';

export function getRequest(url) {
  const token = localStorage.getItem('token');
  if (token) {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } else {
    return Promise.reject('Token is expired');
  }
}

export function postRequest(url, data, options) {
  const token = localStorage.getItem('token');
  if (token) {
    return axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...options
    });
  } else {
    return Promise.reject('Token is expired');
  }
}

export function putRequest(url, data) {
  const token = localStorage.getItem('token');
  if (token) {
    return axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } else {
    return Promise.reject('Token is expired');
  }
}

export function deleteRequest(url) {
  const token = localStorage.getItem('token');
  if (token) {
    return axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } else {
    return Promise.reject('Token is expired');
  }
}
