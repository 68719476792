import React, { useEffect, useState } from 'react';
import Routes from './routes';
import { useAuth0 } from '@auth0/auth0-react';
import ThemeCustomization from './themes';
import Loader from './components/Loader';
import { SnackbarProvider } from 'notistack';

function App() {
  const { isLoading, isAuthenticated, error, getAccessTokenSilently } = useAuth0();
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoadingToken(true);
      getAccessTokenSilently({
        authorizationParams: {
          audience: `https://dev-awb3e8xlpcgkx4i4.us.auth0.com/api/v2/`
        }
      }).then((result) => {
        localStorage.setItem('token', result);
        setIsLoadingToken(false);
      });
    }
  }, [isAuthenticated]);

  if (isLoading || isLoadingToken) {
    return <Loader />;
  }

  return (
    <ThemeCustomization>
      <SnackbarProvider>
        <Routes />
      </SnackbarProvider>
    </ThemeCustomization>
  );
}

export default App;
