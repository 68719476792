import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Divider, Drawer, useMediaQuery } from '@mui/material';

import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from '../../../config';
import Profile from '../Header/HeaderContent/Profile';

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(
    () => <DrawerHeader handleDrawerToggle={handleDrawerToggle} open={open} />,
    [open]
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, height: '100vh' }}
      aria-label="mailbox folders">
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open}>
          <Box>
            {drawerHeader}
            {drawerContent}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
            {/*<Notification />*/}
            <Divider sx={{ my: '24px', width: '70%', borderColor: '#D0D5DD' }} />
            <Profile opened={open} />
          </Box>
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: 'none',
              boxShadow: 'inherit',
              justifyContent: 'space-between',
              overflow: 'visible'
            }
          }}>
          <Box sx={{ background: 'black' }}>
            {open && drawerHeader}
            {open && drawerContent}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default MainDrawer;
