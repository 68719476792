import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setHotkeysConfig } from '../../store/reducers/project';
import { PROJECT_MANAGEMENT_URL } from '../../config';
import { putRequest } from '../../utils/request';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const HotkeyModal = ({ isOpen, setModalOpen }) => {
  const hotkeysConfig = useSelector((state) => state.projectSlice.hotkeysConfig);
  const [hotkeys, setHotkeys] = useState({
    exclude: 'e',
    include: 'i',
    maybe: 'm'
  });
  const [editingKey, setEditingKey] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const startEditing = (key) => {
    setEditingKey(key);
  };

  const setHotkey = (key, value) => {
    setHotkeys((prev) => ({ ...prev, [key]: value }));
  };

  const clearHotkey = (key) => {
    setHotkey(key, null);
    setEditingKey(null);
  };

  const handleKeydown = (event) => {
    event.preventDefault();
    if (editingKey) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        return;
      }
      const conflictingKey = Object.keys(hotkeys).find((k) => hotkeys[k] === event.key);
      if (conflictingKey) {
        setHotkey(conflictingKey, null);
      }

      setHotkey(editingKey, event.key);
      setEditingKey(null);
    }
  };

  const onClose = () => {
    setEditingKey(null);
    setHotkeys(hotkeysConfig);
    setModalOpen(false);
  };

  const onSave = () => {
    putRequest(PROJECT_MANAGEMENT_URL + '/api/hotkeys', hotkeys)
      .then((conf) => {
        onClose();
        dispatch(setHotkeysConfig(conf.data));
        enqueueSnackbar(`Hotkeys changed successfully`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        });
      });
  };

  useEffect(() => {
    if (hotkeysConfig) {
      setHotkeys(hotkeysConfig);
    }
  }, [hotkeysConfig]);

  return (
    <Dialog open={isOpen} onClose={onClose} onKeyDown={handleKeydown} maxWidth="md">
      <DialogTitle>
        <Typography variant="h5">Hotkeys</Typography>
      </DialogTitle>
      <DialogContent sx={{ justifyContent: 'center', alignItemsP: 'center' }}>
        {Object.entries(hotkeys).map(([key, value]) => (
          <div
            key={key}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px'
            }}>
            <div style={{ width: '20%', marginRight: '5%', marginLeft: '10px' }}>
              <Typography variant="h6">{key.toLocaleUpperCase()}:</Typography>
            </div>
            <div style={{ width: '35%', marginRight: '5%' }}>
              <TextField
                fullWidth
                value={value || 'None'}
                onClick={() => startEditing(key)}
                inputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                style={{ textAlign: 'center' }}
              />
            </div>
            <div style={{ marginRight: '10px' }}>
              <IconButton onClick={() => clearHotkey(key)} size="small">
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', alignItemsP: 'center' }}>
        <Button variant="contained" color="primary" size="small" onClick={onSave}>
          Save
        </Button>
        <Button variant="contained" color="secondary" size="small" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HotkeyModal;
