// assets
import { ExpandOutlined, ProjectOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';

// icons
const icons = {
  ProjectOutlined,
  ExpandOutlined,
  DeliveredProcedureOutlined
};

const pages = {
  id: 'pages',
  title: 'Pages',
  type: 'group',
  children: [
    {
      id: 'projects',
      title: 'Selection',
      type: 'item',
      url: '/projects',
      icon: icons.ProjectOutlined,
      breadcrumbs: false
    },
    {
      id: 'Screener',
      title: 'Screening',
      type: 'item',
      url: '/screener',
      icon: icons.ExpandOutlined,
      breadcrumbs: false
    },
    {
      id: 'Export',
      title: 'Export',
      type: 'item',
      url: '/export',
      icon: icons.DeliveredProcedureOutlined,
      breadcrumbs: false
    }
  ]
};

export default pages;
