import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import logoSmall from '../../../../assets/images/logo-small.png';
import logo from '../../../../assets/images/logo.png';

import DrawerHeaderStyled from './DrawerHeaderStyled';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

const DrawerHeader = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        {open ? (
          <img src={logo} style={{ height: '60px' }} />
        ) : (
          <img src={logoSmall} style={{ width: '80px' }} />
        )}
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          right: -13,
          width: '26px',
          height: '26px',
          borderRadius: '13px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          background: '#fff',
          bottom: 0,
          border: '1px solid #DDDDDD'
        }}
        onClick={handleDrawerToggle}>
        {open ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
      </Box>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
